// dependencies.
import React, { useEffect, useState } from 'react'
import EmailValidator from 'email-validator'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// components.
import PageHead from 'src/components/head/PageHead'
import {
  HeaderSection,
  BannerSection,
  CrossChainSection,
  FeaturesSection,
  GuideSection,
  FaqSection,
  //
  ExodusSection,
  FooterSection,
  FormModal,
} from 'src/components/pages/xo-swap'
import { Header, Footer } from 'src/components/layout'
// utils.
import { getCookie, setCookie } from 'src/js/utils/cookies'
import { useDimensions, useRellax } from 'src/js/utils/hooks'
// data.
import XoSwapData from 'src/data/pages/Contents/xo-swap.json'
// constants.
import { HELPERS_URL, XOSWAP_LEAD_SUBSCRIBE_ENABLED } from 'src/constants'

// Styles & Images
import 'static/xo-swap/scss/styles.scss'

// Helpers:
/*
 * Related cookies
 * ex_xoswap_lead_subscribe
 *  - set to "1" for 30 days if the user subscribes
 */
const leadCookie = 'ex_xoswap_lead_subscribe'
const isCookieSet = () => getCookie(leadCookie) === '1'

// Main component:
const XoSwapPage = ({ pageName = 'xo-swap', pageData = XoSwapData }) => {
  // get page data.
  const { content } = pageData
  const {
    header,
    magicEdenBanner,
    rangoBanner,
    apiDocsBanner,
    crossChain,
    features,
    guide,
    faq,
    //
    exodus,
    footer,
    form,
  } = content

  const { width } = useDimensions()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [formState, setFormState] = useState('initial')

  // request demo modal.
  const handleModalOpen = () => {
    setModalIsOpen(!modalIsOpen)
  }

  const handleFormSubmit = (values) => {
    if (!values) return null

    if (!values.email || !EmailValidator.validate(values.email)) {
      setFormState('error')
      return
    }

    setFormState('loading')

    fetch(`${HELPERS_URL}/xoswap/add-lead`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const success = response.status === 200
        const invalid = response.status === 400

        if (success) {
          setFormState('success')
          if (!isCookieSet()) setCookie({ name: leadCookie, value: '1', daysToPersist: 30 })
        } else {
          setFormState(invalid ? 'invalid' : 'error')
        }
      })
      .catch((err) => {
        console.log(err)
        setFormState('error')
      })
  }

  useEffect(() => {
    if (typeof document !== 'undefined' && isCookieSet()) setFormState('success')
    else setFormState('initial')
  }, [])

  useRellax({ className: 'xo-swap-rellax' })

  // get images.
  const images = useStaticQuery(graphql`
    query {
      background400: file(relativePath: { regex: "/^xo-swap\/img\/header-bg-ssize\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 400, placeholder: BLURRED) }
      }
      background800: file(relativePath: { regex: "/^xo-swap\/img\/header-bg-msize\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 800, placeholder: BLURRED) }
      }
      background1600: file(relativePath: { regex: "/^xo-swap\/img\/header-bg-lsize\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      backgroundVideo: file(relativePath: { regex: "/^xo-swap\/img\/JumpingCoins\\.mp4$/" }) {
        publicURL
      }
      backgroundVideoGIF: file(relativePath: { regex: "/^xo-swap\/img\/JumpingCoins\\.gif$/" }) {
        publicURL
      }

      magicEdenBanner: file(relativePath: { regex: "/^xo-swap\/img\/magiceden-banner-image\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }

      rangoBanner: file(relativePath: { regex: "/^xo-swap\/img\/rango-banner-image\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }

      guideBanner: file(relativePath: { regex: "/^xo-swap\/img\/guide-banner-image\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }

      exodusPlatforms: file(relativePath: { regex: "/^xo-swap\/img\/exodus-platforms-image\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
    }
  `)

  const getBackgroundImage = () => {
    if (width && width <= 400) {
      return images.background400.childImageSharp.gatsbyImageData
    } else if (width && width <= 800) {
      return images.background800.childImageSharp.gatsbyImageData
    } else {
      return images.background1600.childImageSharp.gatsbyImageData
    }
  }

  return (
    <div className="x__xo-swap-page">
      <Header />

      <main className="x">
        <div className="x__xo-swap-page__background-container" role="presentation">
          <div className="x__xo-swap-page__background">
            <GatsbyImage image={getBackgroundImage()} alt={header.alt} loading="eager" />
          </div>

          {width && width >= 992 ? (
            <video autoPlay muted loop className="x__xo-swap-page__background-video">
              <source src={images.backgroundVideo.publicURL} type="video/mp4" />
            </video>
          ) : (
            <img
              src={images.backgroundVideoGIF.publicURL}
              alt={header.alt}
              className="x__xo-swap-page__background-video"
            />
          )}
        </div>

        {header && (
          <HeaderSection
            data={header}
            enableSubscribe={XOSWAP_LEAD_SUBSCRIBE_ENABLED}
            onButtonClick={handleModalOpen}
          />
        )}
        {magicEdenBanner && (
          <BannerSection
            data={magicEdenBanner}
            image={images.magicEdenBanner.childImageSharp.gatsbyImageData}
            withChromes
          />
        )}
        {crossChain && (
          <CrossChainSection
            data={crossChain}
            enableSubscribe={XOSWAP_LEAD_SUBSCRIBE_ENABLED}
            onButtonClick={handleModalOpen}
          />
        )}
        {rangoBanner && (
          <BannerSection
            data={rangoBanner}
            image={images.rangoBanner.childImageSharp.gatsbyImageData}
            smallHeading
            withChromes
          />
        )}
        {features && (
          <FeaturesSection
            data={features}
            enableSubscribe={XOSWAP_LEAD_SUBSCRIBE_ENABLED}
            onButtonClick={handleModalOpen}
          />
        )}
        {guide && (
          <GuideSection data={guide} image={images.guideBanner.childImageSharp.gatsbyImageData} />
        )}
        {faq && <FaqSection data={faq} />}
        {apiDocsBanner && <BannerSection data={apiDocsBanner} />}
        {exodus && (
          <ExodusSection
            data={exodus}
            image={images.exodusPlatforms.childImageSharp.gatsbyImageData}
          />
        )}
        {footer && (
          <FooterSection
            data={footer}
            enableSubscribe={XOSWAP_LEAD_SUBSCRIBE_ENABLED}
            onButtonClick={handleModalOpen}
          />
        )}
      </main>

      {/* old */}
      <FormModal
        data={form}
        modalOpen={modalIsOpen}
        state={formState}
        onClose={handleModalOpen}
        onSubmit={(values) => handleFormSubmit(values)}
      />

      <Footer />
    </div>
  )
}

export default XoSwapPage

// <head> component:
export function Head() {
  return <PageHead page="xo-swap" />
}
